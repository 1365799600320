import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startConsultantAction = () => {
  return {
    type: actionTypes.START_CONSULTANT_ACTION
  };
};

export const consultantActionFail = message => {
  return {
    type: actionTypes.CONSULTANT_ACTION_FAIL,
    message
  };
};

export const fetchConsultantListSUCCESS = (consultantList, message = '') => {
  return {
    type: actionTypes.FETCH_CONSULTANT_LIST_SUCCESS,
    listing: consultantList,
    message: message ? message : ''
  };
};

export const fetchConsultantListAction = (data, message = '') => {
  return dispatch => {
    dispatch(startConsultantAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/get-consultant", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchConsultantListSUCCESS(response.data.data, message));
          } else {
            dispatch(consultantActionFail(response.data.message));
          }
        } else {
          dispatch(consultantActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(consultantActionFail(err.message));
      });
  };
};

export const addConsultantAction = (data) => {
  return dispatch => {
    dispatch(startConsultantAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/add-consultant", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          // if (response.data.status === 200) {
            dispatch(fetchConsultantListAction())
          // } else {
          //   dispatch(consultantActionFail(response.data.message));
          // }
        } else {
          dispatch(consultantActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(consultantActionFail(err.message));
      });
  };
};

export const deleteConsultantAction = (id) => {
  return dispatch => {
    dispatch(startConsultantAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .delete(`/admin/delete-consultant/${id}`, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          // if (response.data.status === 200) {
            dispatch(fetchConsultantListAction())
          // } else {
          //   dispatch(consultantActionFail(response.data.message));
          // }
        } else {
          dispatch(consultantActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(consultantActionFail(err.message));
      });
  };
};

export const updateConsultantAction = (data) => {
  return dispatch => {
    dispatch(startConsultantAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/update-consultant", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          // if (response.data.status === 200) {
            dispatch(fetchConsultantListAction())
          // } else {
          //   dispatch(consultantActionFail(response.data.message));
          // }
        } else {
          dispatch(consultantActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(consultantActionFail(err.message));
      });
  };
};
