import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  consultantListing: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_CONSULTANT_ACTION:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.CONSULTANT_ACTION_FAIL:
      return {
        ...state,
        loading: null,
        error: action.message,
        success: null,
      };

    case actionTypes.FETCH_CONSULTANT_LIST_SUCCESS:
      return {
        ...state,
        loading: null,
        consultantListing: action.listing,
        error: null,
        success: action.message,
      };

    default:
      return state;
  }
};

export default reducer;
