import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startbussinessAction = () => {
  return {
    type: actionTypes.START_COUPON_BUSSINESS_ACTION
  };
};

export const bussinessActionFail = message => {
  return {
    type: actionTypes.COUPON_BUSSINESS_ACTION_FAIL,
    message
  };
};

export const getcouponrankingbusinessSuccess = (list) => {
  return {
    type: actionTypes.GET_COUPONRANKING_BUSINESS_USER,
    listing: list,
  };
};

export const fetchcouponbussinessListAction = (message) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .get("/admin/get-couponranking-business-user", {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getcouponrankingbusinessSuccess(response.data.data, message));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

// yaha se upper wala

export const deleteCouponRankingBusinessUser = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .delete("/admin/delete-bussiness-user/" + data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchcouponbussinessListAction(response.data.message))
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const updateVerifyBusiness = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/update-verify-business", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchcouponbussinessListAction(response.data.message))
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};


export const subscriptionPlanListSuccess = (list) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_PLAN_LIST_SUCCESS,
    listing: list,
  };
};

export const getSubscriptionPlan = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/get-advertisement-subscription-listing/" + data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(subscriptionPlanListSuccess(response.data.data))
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};


export const addCouponRankingBusiness = (data, history) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/add-couponranking-business-user", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchcouponbussinessListAction())
            history.push('./coupon-ranking')
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const getBusinessDetailSuccess = (details, uniqueCities) => {
  return {
    type: actionTypes.GET_COUPON_BUSINESS_DETAILS_SUCCESS,
    details: details,
    uniqueCities: uniqueCities
  };
};

export const getSubscriptionListSuccess = (details) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_LIST_DETAILS_SUCCESS,
    details: details,
  };
};

export const getCouponrankingBusinessDetails = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/get-couponranking-business-details", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBusinessDetailSuccess(response.data.data, response.data.uniqueCities))
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const getSubscriptionTimePeriod = () => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .get("/admin/subscription-time-period", {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          console.log(response.data.data, "response.data.data");
          if (response.data.status === 200) {
            dispatch(getSubscriptionListSuccess(response.data.data))
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

const editCouponBusinessSuccess = (details, message) => {
  return {
    type: actionTypes.EDIT_COUPON_BUSINESS_DETAILS_SUCCESS,
    details: details,
    message: message
  };
}

export const editCouponRankingBusiness = (data, history) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/edit-couponranking-business-user", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            let businessId = response.data.data._id
            dispatch(editCouponBusinessSuccess(response.data.data, response.data.message))
            history.push(`/edit-couponranking-business/${businessId}`)
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};


export const getcouponrankingReports = (list) => {
  return {
    type: actionTypes.GET_COUPONRANKING_REPORT_LISTING,
    listing: list,
  };
};


export const getCouponRankingReportList = () => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .get("/admin/get-couponranking-business-reports", {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getcouponrankingReports(response.data.data, ''));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};
export const getDateWiseReportList = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/get-datewise-couponranking-business-reports", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getcouponrankingReports(response.data.data, ''));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};