import React from "react";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "./Spinner/Spinner";
import { MDBDataTable } from "mdbreact";

class SalesConsultant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      open: false,
      formErr: "",
      salesId: "",
      openDialgue: false,
      formErrImage: "",
      file: "",
      driving_licence: "",
      last_name: "",
      formErrlast_name: "",
      email: "",
      formErremail: "",
      phone_number: "",
      formErrphone_number: "",

      city: "",
      formErrcity: "",
      ssn: "",
      formErrssn: "",
      type: "",
      formErrtype: "",
      amount: "",
      formErramount: "",
      base_pay: "",
      formErrbase_pay: "",
      total_sales: "",
      formErrtotal_sales: "",
      total_amount_paid: "",
      formErrtotal_amount_paid: "",
      team_paid: "",
      formErrteam_paid: "",
    };
    this.handleUploadFile = this.handleUploadFile.bind(this)
  }
  componentWillMount() {
    this.props.fetchConsultantListAction();
    console.log("this.props.listing", this.props.consultantListing);

    // console.log(this.props.consultantListing)
  }
  onChangeAddName = async (key, value) => {
    var self = this;
    await self.setState({
      [key]: value,
    });
    if (value) {
      await this.setState({
        formErr: "",
      });
    }
    console.log(self.state.first_name);
  };
  handleSubmit = async () => {
    console.log("simran")
    if (this.state.driving_licence === "") {
      await this.setState({ formErrImage: "Please provide an image" });
    } else if (this.state.first_name === "") {
      await this.setState({ formErr: "Please provide a valid first name" });
    } else if (this.state.last_name === "") {
      await this.setState({
        formErrlast_name: "Please provide a valid last name",
      });
    } else if (this.state.email === "") {
      await this.setState({ formErremail: "Please provide a valid email" });
    } else if (this.state.phone_number === "") {
      await this.setState({
        formErrphone_number: "Please provide a valid phone number",
      });
    } else if (this.state.city === "") {
      await this.setState({ formErrcity: "Please provide a valid city" });
    } else if (this.state.ssn === "") {
      await this.setState({ formErrssn: "Please provide a valid SSN" });
    } else if (this.state.type === "") {
      await this.setState({ formErrtype: "Please provide a valid type" });
    } else if (this.state.amount === "") {
      await this.setState({ formErramount: "Please provide a valid amount" });
    } else if (this.state.base_pay === "") {
      await this.setState({
        formErrbase_pay: "Please provide a valid base pay",
      });
    } else if (this.state.total_sales === "") {
      await this.setState({
        formErrtotal_sales: "Please provide a valid total sales amount",
      });
    } else if (this.state.total_amount_paid === "") {
      await this.setState({
        formErrtotal_amount_paid: "Please provide a valid total amount paid",
      });
    }
    //  else if (this.state.team_paid === "") {
    //   await this.setState({
    //     formErrteam_paid: "Please provide a valid team paid amount",
    //   });
    // } 
    else {
      await this.setState({
        formErr: "",
        formErrlast_name: "",
        formErremail: "",
        formErrphone_number: "",
        formErrcity: "",
        formErrssn: "",
        formErrtype: "",
        formErramount: "",
        formErrbase_pay: "",
        formErrtotal_sales: "",
        formErrtotal_amount_paid: "",
        formErrteam_paid: "",
      });
      console.log(this.state.salesId)
      if (this.state.salesId) {
        if (this.state.driving_licence && this.state.first_name) {
          this.props.updateConsultantAction({
            first_name: this.state.first_name,
            salesId: this.state.salesId,
            last_name: this.state.last_name,
            email: this.state.email,
            phone_number: this.state.phone_number,
            city: this.state.city,
            ssn: this.state.ssn,
            type: this.state.type,
            amount: this.state.amount,
            base_pay: this.state.base_pay,
            total_sales: this.state.total_sales,
            total_amount_paid: this.state.total_amount_paid,
            team_paid: this.state.team_paid,
          });
        }
      } else {
        const data = new FormData();
        if (this.state.driving_licence && this.state.first_name) {
          data.append("first_name", this.state.first_name);
          data.append("driving_licence", this.state.driving_licence);
          data.append("last_name", this.state.last_name);
          data.append("email", this.state.email);
          data.append("phone_number", this.state.phone_number);
          data.append("city", this.state.city);
          data.append("ssn", this.state.ssn);
          data.append("type", this.state.type);
          data.append("amount", this.state.amount);
          data.append("base_pay", this.state.base_pay);
          data.append("total_sales", this.state.total_sales);
          data.append("total_amount_paid", this.state.total_amount_paid);
          data.append("team_paid", this.state.team_paid);

          this.props.addConsultantAction(data);
        }
      }

      this.close();
    }
  };
  handleOpen = (
    id,
    first_name,
    driving_licence,
    last_name,
    email,
    phone_number,
    city,
    ssn,
    type,
    amount,
    base_pay,
    total_sales,
    total_amount_paid,
    team_paid
  ) => {
    if (id !== "") {
      this.setState({
        salesId: id,
        first_name: first_name,
        driving_licence: driving_licence,
        file: driving_licence,
        last_name: last_name,
        email: email,
        phone_number: phone_number,
        city: city,
        ssn: ssn,
        type: type,
        amount: amount,
        base_pay: base_pay,
        total_sales: total_sales,
        total_amount_paid: total_amount_paid,
        team_paid: team_paid,
      });
    } else {
      this.setState({
        salesId: "",
        first_name: "",
        driving_licence: "",
        file: "",
        last_name: "",
        email: "",
        phone_number: "",
        city: "",
        ssn: "",
        type: "",
        amount: "",
        base_pay: "",
        total_sales: "",
        total_amount_paid: "",
        team_paid: "",
      });
    }

    this.setState({
      open: true,
    });
  };
  async close() {
    await this.setState({
      open: false,
      first_name: "",
      formErr: "",
      formErrImage: "",
      driving_licence: "",
      file: "",
      last_name: "",
      email: "",
      phone_number: "",
      city: "",
      formErrcity: "",
      ssn: "",
      formErrssn: "",
      type: "",
      formErrtype: "",
      amount: "",
      formErramount: "",
      base_pay: "",
      formErrbase_pay: "",
      total_sales: "",
      formErrtotal_sales: "",
      total_amount_paid: "",
      formErrtotal_amount_paid: "",
      team_paid: "",
      formErrteam_paid: "",
    });
  }

  handleOpenDelete = (id) => {
    if (id) {
      this.setState({
        salesId: id,
        openDialgue: true,
      });
    }
  };
  open() {
    this.setState({ openDialgue: true });
  }
  closeDialgue() {
    this.setState({ openDialgue: false });
  }
  deleteUserPermanently() {
    if (this.state.salesId) {
      this.props.deleteConsultantAction(this.state.salesId);
      this.closeDialgue();
      this.setState({ salesId: "" });
    }
  }
    handleUploadFile = async(event) => {

  		await this.setState({file: URL.createObjectURL(event.target.files[0]),driving_licence:event.target.files[0],errimage:''})

      if(this.state.salesId) {
        const data = new FormData();

        if(this.state.driving_licence){
          data.append('driving_licence', this.state.driving_licence);
          // name:this.state.first_name,salesId:this.state.salesId
          this.props.updateConsultantActionImage(data,this.state.salesId)

        }
      }

    }
    
  getDate() { 
    this.props.fetchConsultantListAction({
      start_date : this.state.start_date,
      end_date: this.state.end_date,
    })
  }
  render() {
    // console.log(this.state.email,'===========rideshare')
    const { open } = this.state;
    var sno = 1;
    let categoryList = [];
    let businessCategoryTable = <Spinner />;
    {
      this.props.consultantListing?.length > 0
        ? this.props.consultantListing?.map((datas) => {
            // console.log(datas,'======')
            categoryList.push({
              s_no: sno++,
              full_name: datas.first_name + " " + datas.last_name,
              // last_name:datas.last_name,
              driving_licence: (
                <img
                  src={datas.driving_licence}
                  className="rounded-circle mb-15"
                  width="100"
                  height="100"
                ></img>
              ),
              email: datas.email,
              phone_number: datas.phone_number,
              city: datas.city,
              ssn: datas.ssn,
              type: datas.type,
              amount: datas.amount,
              base_pay: datas.base_pay,
              total_sales: datas.total_sales,
              total_amount_paid: datas.total_amount_paid,
              team_paid: datas.team_paid === true ? "Yes": "No",
              action: (
                <div>
                  <Button
                    className="btn btn-icon btn-info btn-sm"
                    onClick={(data_id, name) =>
                      this.handleOpen(
                        datas._id,
                        datas.first_name,
                        datas.driving_licence,
                        datas.last_name,
                        datas.email,
                        datas.phone_number,
                        datas.city,
                        datas.ssn,
                        datas.type,
                        datas.amount,
                        datas.base_pay,
                        datas.total_sales,
                        datas.total_amount_paid,
                        datas.team_paid,
                      )
                    }
                  >
                    <span class="btn-inner--icon">
                      <i class="fas fa-pencil-alt" />
                    </span>
                  </Button>
                  {/* <button class='btn btn-icon btn-danger btn-sm' type='button'>
                <span class='btn-inner--icon'><i class='fas fa-trash' /></span>
              </button> */}
                  <Button
                    className="btn btn-icon btn-danger btn-sm"
                    onClick={(id) => this.handleOpenDelete(datas._id)}
                  >
                    <span class="btn-inner--icon">
                      <i class="fas fa-trash" />
                    </span>
                  </Button>
                </div>
              ),
            });
          })
        : categoryList.push(["No record"]);
    }
    const data = {
      columns: [
        {
          label: "Sr.No",
          field: "s_no",
          sort: "asc",
        },
        {
          label: "full name",
          field: "full_name",
          sort: "asc",
        },
        {
          label: "driving licence",
          field: "driving_licence",
          sort: "asc",
        },
        {
          label: "email",
          field: "email",
          sort: "asc",
        },
        {
          label: "phone number",
          field: "phone_number",
          sort: "asc",
        },
        {
          label: "city",
          field: "city",
          sort: "asc",
        },

        {
          label: "ssn",
          field: "ssn",
          sort: "asc",
        },
        {
          label: "type",
          field: "type",
          sort: "asc",
        },
        {
          label: "amount",
          field: "amount",
          sort: "asc",
        },
        {
          label: "base_pay",
          field: "base_pay",
          sort: "asc",
        },
        {
          label: "total_sales",
          field: "total_sales",
          sort: "asc",
        },
        {
          label: "total_amount_paid",
          field: "total_amount_paid",
          sort: "asc",
        },
        {
          label: "team_paid",
          field: "team_paid",
          sort: "asc",
        },

        {
          label: "Action",
          field: "action",
          sort: "asc",
        },
      ],
      rows: categoryList,
    };

    businessCategoryTable = (
      <MDBDataTable striped bordered hover entries={10} data={data} />
    );
    const columns = ["Sr.No", "Category Name", "Action"];
    return (
      <div>
        <div className="header bg-primary pb-6">
          <div className="container-fluid">
            <div className="header-body">
              <div className="row align-items-center py-4">
                <div className="col-lg-6 col-7">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Manage Sales Person
                  </h6>
                </div>
              </div>
              <Dialog
                open={this.state.openDialgue}
                onClose={() => this.closeDialgue()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Do you want to delete Sales Person?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button
                    class="btn btn-icon btn-danger btn-sm"
                    type="button"
                    onClick={() => this.closeDialgue()}
                  >
                    No
                  </button>
                  <button
                    class="btn btn-icon btn-success btn-sm"
                    onClick={() => this.deleteUserPermanently()}
                    type="button"
                  >
                    Yes
                  </button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>

        <div className="container-fluid mt--6">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header border-0">
                <div className='card-header border-0'>
                  <div className='row align-items-center py-4'>
                    {/* <div className='col-lg-3'>
                      <select className='form-control' id='exampleFormControlSelect1'>
                        <option>All Countries</option>
                        <option>Deactive</option>
                        <option>All</option>
                      </select>
                    </div> */}
                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col form-inline'>
                          <div className='form-group'>
                            <label className='form-control-label mr-2' for='from'>From</label>
                            <input type='date' id='from' className='form-control'
                            max={this.state.start} 
                            onChange={(e) => this.onChangeAddName('start_date', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className='col form-inline'>
                          <div className='form-group'>
                            <label className='form-control-label mr-2' for='to'>To Date</label>
                            <input type='date' id='to' className='form-control' 
                            onChange={(e) => this.onChangeAddName('end_date', e.target.value)}
                            />
                          </div>
                        </div>
                        
                        <div className='col form-inline'>
                          <div className='form-group'>
                            <button
                              className='btn btn-success float-right'
                              type='button'
                              onClick={(e) => this.getDate()}
                            >Get Data</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6" />
                    <div className="col-lg-6">
                      <Button
                        className="btn btn-primary float-right"
                        onClick={(data_id, name) => this.handleOpen("", "", "")}
                      >
                        Add Sales Consultant
                      </Button>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {businessCategoryTable}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={open} toggle={() => this.close()}>
            <ModalHeader toggle={() => this.close()}>
              <Label for="title">Add Category</Label>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <img
                  src={
                    this.state.driving_licence === ""
                      ? "../assets/img/noimage.png"
                      : this.state.driving_licence
                  }
                  alt="user prof"
                  className="rounded-circle mb-15"
                  width="100"
                  height="100"
                />
                <br />
                <Label for="File-2">Upload Image</Label>
                <Input
                  type="file"
                  name="driving_licence"
                  id="File-2"
                  onChange={this.handleUploadFile}
                />
                <span style={{ color: "red" }}>{this.state.formErrImage}</span>
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="First Name"
                  value={this.state.first_name}
                  onChange={(e) =>
                    this.onChangeAddName("first_name", e.target.value)
                  }
                />
                <span style={{ color: "red" }}>{this.state.formErr}</span>
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Last Name"
                  value={this.state.last_name}
                  onChange={(e) =>
                    this.onChangeAddName("last_name", e.target.value)
                  }
                ></Input>
                <span style={{ color: "red" }}>
                  {this.state.formErrlast_name}
                </span>
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="email"
                  value={this.state.email}
                  onChange={(e) =>
                    this.onChangeAddName("email", e.target.value)
                  }
                >
                </Input>
                <span style={{ color: "red" }}>{this.state.formErremail}</span>
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  placeholder="Phone Number"
                  value={this.state.phone_number}
                  onChange={(e) =>
                    this.onChangeAddName("phone_number", e.target.value)
                  }
                />
                <span style={{ color: "red" }}>
                  {this.state.formErrphone_number}
                </span>
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="city"
                  id="city"
                  placeholder="City"
                  value={this.state.city}
                  onChange={(e) => this.onChangeAddName("city", e.target.value)}
                />
                <span style={{ color: "red" }}>{this.state.formErrcity}</span>
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="ssn"
                  id="ssn"
                  placeholder="SSN"
                  value={this.state.ssn}
                  onChange={(e) => this.onChangeAddName("ssn", e.target.value)}
                />
                <span style={{ color: "red" }}>{this.state.formErrssn}</span>
              </FormGroup>

              <FormGroup>
                <Input
                  type="select"
                  name="type"
                  id="type"
                  placeholder="Type"
                  value={this.state.type}
                  onChange={(e) => this.onChangeAddName("type", e.target.value)}
                >
                    <option value="salesConsultant">Sales Consultant</option>
                    <option value="salesManager">Sales Manager</option>
                    <option value="referral">Referral</option>

                    </Input>
                <span style={{ color: "red" }}>{this.state.formErrtype}</span>
              </FormGroup>

              <FormGroup>
                <Input
                  type="number"
                  name="amount"
                  id="amount"
                  placeholder="Amount"
                  value={this.state.amount}
                  onChange={(e) =>
                    this.onChangeAddName("amount", e.target.value)
                  }
                />
                <span style={{ color: "red" }}>{this.state.formErramount}</span>
              </FormGroup>

              <FormGroup>
                <Input
                  type="number"
                  name="base_pay"
                  id="base_pay"
                  placeholder="Base Pay"
                  value={this.state.base_pay}
                  onChange={(e) =>
                    this.onChangeAddName("base_pay", e.target.value)
                  }
                />
                <span style={{ color: "red" }}>
                  {this.state.formErrbase_pay}
                </span>
              </FormGroup>

              <FormGroup>
                <Input
                  type="number"
                  name="total_sales"
                  id="total_sales"
                  placeholder="Total Sales"
                  value={this.state.total_sales}
                  onChange={(e) =>
                    this.onChangeAddName("total_sales", e.target.value)
                  }
                />
                <span style={{ color: "red" }}>
                  {this.state.formErrtotal_sales}
                </span>
              </FormGroup>

              <FormGroup>
                <Input
                  type="number"
                  name="total_amount_paid"
                  id="total_amount_paid"
                  placeholder="Total Amount Paid"
                  value={this.state.total_amount_paid}
                  onChange={(e) =>
                    this.onChangeAddName("total_amount_paid", e.target.value)
                  }
                />
                <span style={{ color: "red" }}>
                  {this.state.formErrtotal_amount_paid}
                </span>
              </FormGroup>

              <FormGroup>
                <Input
                  type="select"
                  name="team_paid"
                  id="team_paid"
                  placeholder="Team Paid"
                  value={this.state.team_paid}
                  onChange={(e) =>
                    this.onChangeAddName("team_paid", e.target.value)
                  }
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </Input>
                <span style={{ color: "red" }}>
                  {this.state.formErrteam_paid}
                </span>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="raised"
                className="btn-success text-white mr-10"
                onClick={(e) => this.handleSubmit()}
              >
                {this.state.salesId !== "" ? "Update" : "Save"}
              </Button>
              <Button
                variant="raised"
                onClick={() => this.close()}
                className="btn-danger text-white mr-10"
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <footer className="footer pt-0">
            <div className="row align-items-center justify-content-lg-between">
              <div className="col-lg-6">
                <div className="copyright text-center text-lg-left  text-muted mt-4 ml-4">
                  &copy; 2021 ServiceHubConnect
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
const mapPropsToState = (state) => {
  return {
    consultantListing: state.salesConsultant.consultantListing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchConsultantListAction: (data) =>
      dispatch(actions.fetchConsultantListAction(data)),
    addConsultantAction: (data) => dispatch(actions.addConsultantAction(data)),
    updateConsultantAction: (data) =>
      dispatch(actions.updateConsultantAction(data)),
    deleteConsultantAction: (data) =>
      dispatch(actions.deleteConsultantAction(data)),
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(SalesConsultant));
