import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  purchaseProoflisting: [],
  rebateConfirmationlisting: [],
};

const reducer = (state = initialState, action) => {
  console.log(action, "action")
   switch (action.type) {
    case actionTypes.START_PURCHASE_PURCHASE_ACTION:
      return {
          ...state,
          loading:true,
          error:null
      };
    case actionTypes.PURCHASE_PURCHASE_ACTION_FAIL :
        return {
            ...state,
            loading:null,
            error:action.message,
            success:null,
        }
    case actionTypes.FETCH_PURCHASE_PURCHASE_LIST_SUCCESS :
        return {
            ...state,
            loading:null,
            purchaseProoflisting:action.listing,
            error:null,
            success:action.message,
            
        }
    case actionTypes.FETCH_REBATE_CONFIRMATION_LIST_SUCCESS : 
        return {
          ...state,
            loading:null,
            rebateConfirmationlisting:action.listing,
            error:null,
            success:action.message,
        }
    default:
      return state;
  }
};

export default reducer;
