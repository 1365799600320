import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startPurchaseProofAction = () => {
  return {
    type: actionTypes.START_PURCHASE_PURCHASE_ACTION
  };
};

export const PurchaseProofActionFail = message => {
  return {
    type: actionTypes.PURCHASE_PURCHASE_ACTION_FAIL,
    message
  };
};

export const fetchPurchaseProofListSUCCESS = (PurchaseProofList,message = '') => {
  return {
    type: actionTypes.FETCH_PURCHASE_PURCHASE_LIST_SUCCESS,
    listing: PurchaseProofList,
    message: message ? message : ''
  };
};

export const fetchRebateConfirmationListSUCCESS = (PurchaseProofList,message = '') => {
  return {
    type: actionTypes.FETCH_REBATE_CONFIRMATION_LIST_SUCCESS,
    listing: PurchaseProofList,
    message: message ? message : ''
  };
};

export const fetchPurchaseProofListAction = (data) => {
  return dispatch => {
    dispatch(startPurchaseProofAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/get-proof-of-purchase", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            console.log("response.data", response.data)
            dispatch(fetchPurchaseProofListSUCCESS(response.data.data,''));
          } else {
            dispatch(PurchaseProofActionFail(response.data.message));
          }
        } else {
          dispatch(PurchaseProofActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(PurchaseProofActionFail(err.message));
      });
  };
};

export const fetchRebateConfirmationListAction = (data) => {
  return dispatch => {
    dispatch(startPurchaseProofAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/get-rebate-data", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            console.log("response.data", response.data)
            dispatch(fetchRebateConfirmationListSUCCESS(response.data.data,''));
          } else {
            dispatch(PurchaseProofActionFail(response.data.message));
          }
        } else {
          dispatch(PurchaseProofActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(PurchaseProofActionFail(err.message));
      });
  };
};
export const addPurchaseProofAction = (data) => {
  return dispatch => {
    dispatch(startPurchaseProofAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/create-PurchaseProof", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchPurchaseProofListAction())
          } else {
            dispatch(PurchaseProofActionFail(response.data.message));
          }
        } else {
          dispatch(PurchaseProofActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(PurchaseProofActionFail(err.message));
      });
  };
};

export const PurchaseProofDeleteAction = (data) => {
  return dispatch => {
    dispatch(startPurchaseProofAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .delete("/admin/delete-PurchaseProof/"+data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchPurchaseProofListAction())
          } else {
            dispatch(PurchaseProofActionFail(response.data.message));
          }
        } else {
          dispatch(PurchaseProofActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(PurchaseProofActionFail(err.message));
      });
  };
};

export const PurchaseProofPaidAction = (data) => {
  return dispatch => {
    dispatch(startPurchaseProofAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .put("/admin/cost-PurchaseProof/"+data, {
        // headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchPurchaseProofListAction())
          } else {
            dispatch(PurchaseProofActionFail(response.data.message));
          }
        } else {
          dispatch(PurchaseProofActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(PurchaseProofActionFail(err.message));
      });
  };
};
export const updatePurchaseProofAction = (data) => {
  
  return dispatch => {
    dispatch(startPurchaseProofAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    
    axios
      .put("/admin/edit-PurchaseProof/"+data.categoryId, data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
           
            dispatch(fetchPurchaseProofListAction())
          } else {
            dispatch(PurchaseProofActionFail(response.data.message));
          }
        } else {
          dispatch(PurchaseProofActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(PurchaseProofActionFail(err.message));
      });
  };
};
