import React from 'react'
import {
  BrowserRouter as Router,
  NavLink,
  Link,
} from 'react-router-dom'
import * as actions from './store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import MUIDataTable from 'mui-datatables'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Spinner from "./Spinner/Spinner";
import { MDBDataTable } from "mdbreact";

class AdvertiseSubscription extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      categoryName: '',
      open: false,
      adv_subscription_id: '',
      openDialgue: false,
      monthlyBottonColor:"#2dce89",
      yearlyBottonColor:"#0a68ae",
      oneTimeBottonColor:"#0a68ae",
      quoteBottonColor:"#0a68ae",
      selectedCity: "", 
    }
  }
  componentWillMount() {
    this.props.advertiseSubscriptionList({ subscription_type: "monthly" })
  }
  // Function to extract unique cities
  getUniqueCities = () => {
    const { subscriptionPlanlist } = this.props;
    const cities = subscriptionPlanlist.map((plan) => plan.city); // Extract city names
    return [...new Set(cities)]; // Remove duplicates
  };

  // Handle dropdown change
  handleCityChange = (event) => {
    this.setState({ selectedCity: event.target.value });
    console.log(event.target.value)
  };

  // Function to filter data based on selected city
  getFilteredPlans = () => {
    const { subscriptionPlanlist } = this.props;
    const { selectedCity } = this.state;

    if (!selectedCity) return subscriptionPlanlist; // Show all if no city selected
    return subscriptionPlanlist.filter((plan) => plan.city === selectedCity);
  };


  handleOpenDelete = (id) => {
    if (id) {
      this.setState({
        openDialgue: true,
        adv_subscription_id:id
      });
    }

  }
  open() {
    this.setState({ openDialgue: true });
  }
  closeDialgue() {
    this.setState({ openDialgue: false });
  }
  deleteUserPermanently() {
    if (this.state.adv_subscription_id) {
      this.props.deleteSubscriptionPlan({adv_subscription_id:this.state.adv_subscription_id});
      this.closeDialgue()
      this.setState({ adv_subscription_id: '' });
    }
  }

  getMontlyPlan() {
    this.props.advertiseSubscriptionList({ subscription_type: "monthly" })
    this.setState(
      {
        monthlyBottonColor:"#2dce89",
        yearlyBottonColor:"#0a68ae",
        oneTimeBottonColor:"#0a68ae",
        quoteBottonColor:"#0a68ae",
      }
    )
  }
  getYearlyPlan() {
    this.props.advertiseSubscriptionList({ subscription_type: "yearly" })
    this.setState(
      {
        monthlyBottonColor:"#0a68ae",
        yearlyBottonColor:"#2dce89",
        oneTimeBottonColor:"#0a68ae",
        quoteBottonColor:"#0a68ae",
      }
    )
  }
  getOneTimePlan() {
    this.props.advertiseSubscriptionList({ subscription_type: "onetime" })
    this.setState(
      {
        monthlyBottonColor:"#0a68ae",
        yearlyBottonColor:"#0a68ae",
        oneTimeBottonColor:"#2dce89",
        quoteBottonColor:"#0a68ae",
      }
    )
  }

  getQuotePlan(){
    this.props.advertiseSubscriptionList({ subscription_type: "quote" })
    this.setState(
      {
        monthlyBottonColor:"#0a68ae",
        yearlyBottonColor:"#0a68ae",
        oneTimeBottonColor:"#0a68ae",
        quoteBottonColor:"#2dce89",
      }
    )
  }

  render() {
    const { open } = this.state;
    var sno = 1
    let advSubscriptionList = [];
    const uniqueCities = this.getUniqueCities();
    const filteredPlans = this.getFilteredPlans();

    let subscriptionPlanTable = <Spinner />;
    {
      this.props.subscriptionPlanlist.length > 0
      ? this.props.subscriptionPlanlist.map(datas => {
        advSubscriptionList.push(
          {

            s_no: (sno++),
            advType: datas.advertisement_type,
            subsType: datas.subscription_type,
            originalprice: datas.original_price,
            price: datas.price,
            planDays: datas.plan_day,
            city: datas.city,
            action: <div>
              <Button className='btn btn-icon btn-danger btn-sm'
                onClick={(id) => this.handleOpenDelete(datas._id)} >
                <span class='btn-inner--icon'><i class='fas fa-trash' /></span>
              </Button>
              <NavLink className='btn btn-icon btn-default btn-sm' to={'/edit-advertise-subscription/'+datas._id} >
              <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
            </NavLink>
            </div>

          }
        )
      })
      : advSubscriptionList.push(
        ['No record']
      )
    }

    const filteredList = advSubscriptionList.filter(
      (datas) => this.state.selectedCity === "" || datas.city === this.state.selectedCity
    );

    const data = {
      columns: [
        {
          label: "Sr.No",
          field: "s_no",
          sort: "asc",
        },
        {
          label: "Advertisement Type",
          field: "advType",
          sort: "asc",
        },
        {
          label: "City",
          field: "city",
          sort: "asc"
        },
        {
          label: "Subscription Type",
          field: "subsType",
          sort: "asc",
        },
        {
          label: "Original Price (in $)",
          field: "originalprice",
          sort: "asc",
        },
        {
          label: "Price (in $)",
          field: "price",
          sort: "asc",
        },
        {
          label: "Plan Days",
          field: "planDays",
          sort: "asc",
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
        },
      ],
      rows: filteredList,
    };

    subscriptionPlanTable = (
      <MDBDataTable striped bordered hover entries={10} data={data} />
    );
    // const columns = ['Sr.No', 'Category Name', 'Action']
    let monthColor = this.state.monthlyBottonColor;
    let yearColor = this.state.yearlyBottonColor;
    let onetimeColor = this.state.oneTimeBottonColor;
    let quotetimeColor = this.state.quoteBottonColor;

    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Advertisement Subscription Plan</h6>
                </div>
              </div>
              <Dialog
                open={this.state.openDialgue}
                onClose={() => this.closeDialgue()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Do you want to delete Adverisement Subscription Plan?</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">

                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button class='btn btn-icon btn-danger btn-sm' type='button' onClick={() => this.closeDialgue()}>
                    No
                  </button>
                  <button class='btn btn-icon btn-success btn-sm' onClick={() => this.deleteUserPermanently()} type='button'>
                    Yes
                  </button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>

        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col'>
              <div className='card'>
                <div className='card-header border-0'>
                  <div className='row align-items-center py-4'>
                    <div className='col-lg-6' >
                    <Button class='btn btn-primary float-left' onClick={() => this.getMontlyPlan()} style={{background:monthColor,color:"white"}}>Montly Plan</Button>
                    <Button class='btn btn-primary float-center' onClick={() => this.getYearlyPlan()} style={{background:yearColor,color:"white"}}>Yearly Plan</Button>
                    <Button class='btn btn-primary float-right' onClick={() => this.getOneTimePlan()} style={{background:onetimeColor,color:"white"}}>OneTime Plan</Button>
                    <Button class='btn btn-primary float-right' onClick={() => this.getQuotePlan()} style={{background:quotetimeColor,color:"white"}}>Quote Plan</Button>
                    <select onChange={this.handleCityChange}>
                      <option value="">All Cities</option>
                      {uniqueCities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>

                      </div>
                      <div className='col-lg-3'>
                      <Link class='btn btn-primary float-right' to='/get-advertise-subscribed-Business-user-list'>Subscribed Business User List</Link>
                    </div>
                    <div className='col-lg-3'>
                      <Link class='btn btn-primary' to='/add-advertise-subscription'>Add Advertisement Subscription Plan</Link>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {subscriptionPlanTable}
                  </div>

                </div>
              </div>
            </div>
          </div>

          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                  &copy; 2021  ServiceHubConnect
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}
const mapPropsToState = (state) => {
  return {
    categorylisting: state.category.categorylisting,
    subscriptionPlanlist: state.advertiseSubscription.subscriptionPlanlist
  }
}

const mapDispatchToProps = dispatch => {
  return {
    advertiseSubscriptionList: (data) => dispatch(actions.advertiseSubscriptionList(data)),
    deleteSubscriptionPlan:(data)=>dispatch(actions.deleteSubscriptionPlan(data)),
  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(AdvertiseSubscription))
